@keyframes moving-header {
  from {
    translate: 0 -10%;
    opacity: 0.25;
  }
  to {
    translate: 0 10%;
    opacity: 1;
  }
}

.header {
  span {
    display: inline-block;
    translate: 0 -10%;
    animation-name: moving-header;
    animation-duration: 3s;
    animation-direction: alternate;
    animation-fill-mode: forwards;
  }

  @for $i from 1 through 8 {
    span:nth-child(8n + #{$i}) {
      animation-delay: #{$i / 10}s;
    }
  }
}

img.portrait {
  width: 75%;
  margin-top: 25px;
  border-radius: 25px;
  -webkit-box-shadow: 0px 0px 38px 0px rgba(135,4,87,0.57);
  -moz-box-shadow: 0px 0px 38px 0px rgba(135,4,87,0.57);
  box-shadow: 0px 0px 38px 0px rgba(135,4,87,0.57);

  @media (min-width: 768px) {
    margin-top: 0;
  }

  @media (max-width: 992px) {
    width: 350px;
    margin-top: 25px;
  }
}
